import { Image } from "@nextui-org/image"
import { FC } from "react"

import { SocialIcons } from "./SocialIcons"

import { TOrganizer } from "@/api/organizer"

type Props = {
  organizers: TOrganizer[]
  containSnsIcons?: boolean
}

export const OrganizerInfo: FC<Props> = ({
  organizers,
  containSnsIcons = true,
}) => {
  return (
    <div className="flex flex-col gap-2">
      {organizers.map((item) => {
        return (
          <div
            key={item.id}
            className="flex items-center justify-between gap-2"
          >
            <div className="flex min-w-0 flex-1 items-center gap-2">
              <div className="flex-shrink-0">
                <Image
                  src={item.info.logoImage}
                  alt={`${item.info.name}のロゴ`}
                  width={40}
                  height={40}
                  radius="full"
                />
              </div>
              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-lg font-semibold">
                {item.info.name}
              </p>
            </div>
            {containSnsIcons && <SocialIcons urls={item.info.urls} />}
          </div>
        )
      })}
    </div>
  )
}
