"use client"

import { FC } from "react"

import { ParticipatingHackathonCard } from "./ParticipatingHackathonCard"

import { TRaidSummary } from "@/api/raid"

type Props = {
  participatingHackathons: TRaidSummary[]
}

export const ParticipatingHackathonList: FC<Props> = ({
  participatingHackathons,
}) => {
  if (participatingHackathons.length === 0) {
    return null
  }

  return (
    <div className="space-y-4 backdrop-blur-lg">
      <div className="space-y-2">
        <p className="text-sm">
          {participatingHackathons.length}件のハッカソンに出場中
        </p>
        <h2 className="text-xl font-bold">出場確定のハッカソン</h2>
      </div>

      <div className="flex flex-wrap gap-10">
        {participatingHackathons.map((hackathon) => (
          <ParticipatingHackathonCard
            key={hackathon.info.id}
            hackathon={hackathon}
          />
        ))}
      </div>
    </div>
  )
}
